import React from 'react'
import { ShellProps } from '../src/types/shell'
import { Stack } from '@jarvis/web-stratus-client'
import projectNames from '../src/configs/projectNames'
import TrafficDirector from '../src/TrafficDirector'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root(props) {
  const {
    stack = Stack.pie,
    sku,
    printer,
    paas,
    pageDataCache,
    flags,
    header,
    sidebar,
    footer,
    os,
    isOsSupported,
    showInstallButton,
    isBrowserSupported,
    tenantType,
    setTenantType,
    contentStackCredential,
    currentPath,
    isHpPlus,
    isMobile,
    compatibilityDefinition,
    error,
    setError
  } = props

  const {
    v1: { navigation, localization, analytics }
  } = window.Shell as ShellProps

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <TrafficDirector
        stack={stack}
        localization={localization}
        navigation={navigation}
        header={header}
        sidebar={sidebar}
        footer={footer}
        analytics={analytics}
        sku={sku}
        printer={printer}
        paas={paas}
        pageDataCache={pageDataCache}
        flags={flags}
        os={os}
        isOsSupported={isOsSupported}
        showInstallButton={showInstallButton}
        isBrowserSupported={isBrowserSupported}
        tenantType={tenantType}
        setTenantType={setTenantType}
        contentStackCredential={contentStackCredential}
        currentPath={currentPath}
        isHpPlus={isHpPlus}
        isMobile={isMobile}
        compatibilityDefinition={compatibilityDefinition}
        error={error}
        setError={setError}
      />
    </section>
  )
}
