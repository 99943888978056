import React from 'react'
import ConfigProvider from './store/ConfigContext'
import UserProvider from './store/UserContext'
import App from './App'
import { ContentStackProvider } from '@jarvis/olex-content-management'
import { BrowserRouter as Router } from 'react-router-dom'

const TrafficDirector = (props) => {
  const { localization, contentStackCredential } = props

  return (
    <Router
      basename={`/${props.localization.country.toLowerCase()}/${
        props.localization.language
      }`}
    >
      <ContentStackProvider
        credential={contentStackCredential}
        locale={localization}
      >
        <ConfigProvider {...props}>
          <UserProvider>
            <App />
          </UserProvider>
        </ConfigProvider>
      </ContentStackProvider>
    </Router>
  )
}

export default TrafficDirector
