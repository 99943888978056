import React, { useContext, useEffect, useState } from 'react'
import { ConfigContext } from './ConfigContext'
import { LocalStoreKeys } from './Constants'

export const UserContext = React.createContext({
  betaCode: null,
  setDeltaCode: () => null
})

const UserProvider = (props) => {
  const [deltaCode, setDeltaCode] = useState(null)
  const [loadedFromStore, setLoadedFromStore] = useState(false)
  const { getStoreData, setStoreData } = useContext(ConfigContext)

  useEffect(() => {
    if (!loadedFromStore) {
      const userData = getStoreData(LocalStoreKeys.user)
      if (userData) {
        setDeltaCode(userData.deltaCode)
      }
      setLoadedFromStore(true)
    }
  }, [loadedFromStore, getStoreData])

  useEffect(() => {
    if (loadedFromStore) {
      setStoreData(LocalStoreKeys.user, {
        deltaCode
      })
    }
  }, [deltaCode])

  return (
    <UserContext.Provider
      value={{
        deltaCode,
        setDeltaCode,
        loadedFromStore
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export default UserProvider
