import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Route, Routes } from 'react-router-dom'
import './styles/global.scss'
import { ConfigContext } from './store/ConfigContext'
import useBetaAccessCodeValidation from './hooks/useBetaAccessCodeValidation'
import { UserContext } from './store/UserContext'
import {
  ContentStackTypes,
  FeatureFlagKeys,
  Instructions,
  Paths,
  PrinterFamily
} from './store/Constants'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import {
  CarouselPage,
  ConnectPrinterToNetworkPage,
  ConnectUsbPage,
  DeltaCodePage,
  DriverNotFoundPage,
  FinishSetupBusinessPage,
  HpSoftwarePage,
  InstallDriverPage,
  InstallPage,
  NotFoundPage,
  PrinterSpecificLandingPage,
  PrinterUseChoicePage,
  SelectUsbOnDisplayPage,
  SetupChecklistPage,
  SkipInternetConnectionPage,
  StartAirprintPage,
  StartPrintingMobilePage,
  SubtabPage,
  UnsupportedOsPage
} from './pages/LazyPages'
import { ContentStackContext } from '@jarvis/olex-content-management'
import { printerFamilyByDerivativeName } from './store/Utils'

const App = () => {
  const {
    navigation,
    header,
    sidebar,
    footer,
    sku,
    flags,
    currentPath,
    isLoaded,
    printer
  } = useContext(ConfigContext)
  const { deltaCode, loadedFromStore } = useContext(UserContext)
  const { livePreviewInfo } = useContext(ContentStackContext)
  const {
    [FeatureFlagKeys.betaAccessCode]: deltaCodeFlag,
    [FeatureFlagKeys.saltString]: saltString
  } = flags || {}
  const [userVerifiedByCache, setUserVerifiedByCache] = useState(false)
  const { codeInvalid, validated, setValidated, validate } =
    useBetaAccessCodeValidation()

  const deltaCodeSubmissionHandler = useCallback(
    (validity) => {
      setUserVerifiedByCache(true)
      setValidated(validity)
    },
    [setValidated]
  )

  useEffect(() => {
    if (!deltaCodeFlag) {
      setUserVerifiedByCache(true)
    }
  }, [deltaCodeFlag])

  useEffect(() => {
    if (deltaCodeFlag && loadedFromStore && deltaCode && !userVerifiedByCache) {
      validate(deltaCode, saltString, deltaCodeFlag, deltaCodeSubmissionHandler)
    }
  }, [
    deltaCodeFlag,
    loadedFromStore,
    deltaCode,
    userVerifiedByCache,
    validate,
    deltaCodeSubmissionHandler,
    saltString
  ])

  const printerFamily = useMemo(
    () => printerFamilyByDerivativeName(printer?.derivativeName),
    [printer]
  )
  const flowersDerivativeName = [
    PrinterFamily.FLOWERS_LOTUS,
    PrinterFamily.FLOWERS_CHERRY
  ].includes(printerFamily)

  const trilliumDerivativeName = [
    PrinterFamily.TRILLIUM_PLUS,
    PrinterFamily.TRILLIUM_BASE
  ].includes(printerFamily)

  const showDeltaPage =
    deltaCodeFlag &&
    (flowersDerivativeName || trilliumDerivativeName) &&
    (!deltaCode || (userVerifiedByCache && validated && codeInvalid))

  const showSidebarAndFooter = useMemo(() => {
    const pathsWithoutSidebarFooter =
      currentPath === Paths.not_found ||
      currentPath.toUpperCase().includes(sku?.toUpperCase())

    return (
      isLoaded &&
      !pathsWithoutSidebarFooter &&
      !showDeltaPage &&
      header.selectedTab === 0
    )
  }, [isLoaded, sku, currentPath, showDeltaPage, header.selectedTab])

  useEffect(() => {
    footer.setFooterNavVisibility(showSidebarAndFooter)
    sidebar.setSidebarVisibility(showSidebarAndFooter)
  }, [showSidebarAndFooter])

  if (
    showDeltaPage ||
    livePreviewInfo?.contentTypeUid ===
      ContentStackTypes.delta_access_page.content_type_uid
  )
    return (
      <Suspense
        fallback={
          <ProgressIndicator behavior={'indeterminate'} appearance="linear" />
        }
      >
        <DeltaCodePage
          salt={saltString}
          result={deltaCodeFlag}
          onCodeSubmit={deltaCodeSubmissionHandler}
        />
      </Suspense>
    )

  if (
    !isLoaded &&
    ![Paths.not_found, Paths.install].includes(navigation?.location?.pathname)
  )
    return <ProgressIndicator behavior={'indeterminate'} appearance="linear" />

  if (
    header.selectedTab > 0 &&
    navigation.location.pathname !== Paths.not_found
  )
    return (
      <Suspense
        fallback={
          <ProgressIndicator behavior={'indeterminate'} appearance="linear" />
        }
      >
        <SubtabPage />
      </Suspense>
    )

  return (
    <>
      <Suspense
        fallback={
          <ProgressIndicator behavior={'indeterminate'} appearance="linear" />
        }
      >
        <Routes>
          <Route
            path={Paths.load_paper}
            element={
              <CarouselPage
                key="load-paper"
                instruction={Instructions.load_paper}
              />
            }
          />
          <Route
            path={Paths.install_ink}
            element={
              <CarouselPage
                key="install-ink"
                instruction={Instructions.install_ink}
              />
            }
          />
          <Route
            path={Paths.alignment}
            element={
              <CarouselPage
                key="alignment"
                instruction={Instructions.alignment}
              />
            }
          />
          <Route
            path={Paths.hp_software_trillium}
            element={
              <CarouselPage
                key="hp-software-trillium"
                instruction={Instructions.hp_software}
              />
            }
          />
          <Route
            path={Paths.connect_printer_to_network}
            element={<ConnectPrinterToNetworkPage />}
          />
          <Route
            path={Paths.printer_use_choice}
            element={<PrinterUseChoicePage />}
          />
          <Route
            path={Paths.setup_checklist}
            element={<SetupChecklistPage />}
          />
          <Route path={Paths.connect_usb} element={<ConnectUsbPage />} />
          <Route
            path={Paths.select_usb_on_display}
            element={<SelectUsbOnDisplayPage />}
          />
          <Route path={Paths.driver_download} element={<InstallDriverPage />} />
          <Route path={Paths.hp_software} element={<HpSoftwarePage />} />
          <Route
            path={Paths.finish_setup_business}
            element={<FinishSetupBusinessPage />}
          />
          <Route path={Paths.driver_after} element={<DriverNotFoundPage />} />
          <Route path={Paths.not_found} element={<NotFoundPage />} />
          <Route path={Paths.unsupported_os} element={<UnsupportedOsPage />} />
          <Route
            path={Paths.skip_internet_connection}
            element={<SkipInternetConnectionPage />}
          />
          <Route path={Paths.start_airprint} element={<StartAirprintPage />} />
          <Route
            path={Paths.start_printing_mobile}
            element={<StartPrintingMobilePage />}
          />
          <Route path={Paths.install} element={<InstallPage />} />
          <Route path={Paths.home} element={<PrinterSpecificLandingPage />} />
        </Routes>
      </Suspense>
    </>
  )
}

export default App
