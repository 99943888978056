import { useContext, useState } from 'react'
import { UserContext } from '../store/UserContext'
import { hashString } from '../store/Utils'

const useBetaAccessCodeValidation = () => {
  const { setDeltaCode } = useContext(UserContext)
  const [codeInvalid, setCodeInvalid] = useState(false)
  const [validated, setValidated] = useState(false)

  const validate = (code, salt, result, onCodeSubmit = () => null) => {
    setCodeInvalid(false)
    setValidated(false)
    hashString(code, salt).then((hashedString) => {
      setValidated(true)

      if (hashedString === result) {
        setDeltaCode(code)
        setCodeInvalid(false)
        onCodeSubmit(true)
      } else {
        setDeltaCode(null)
        setCodeInvalid(true)
        onCodeSubmit(false)
      }
    })
  }

  return {
    codeInvalid,
    validated,
    validate,
    setValidated
  }
}

export default useBetaAccessCodeValidation
